

export default function DataRow({label, value }) {

    return (
        <div style={{display: "flex"}}>
            <div style={{margin: ".2rem .5rem", whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>{label}</div>
            <div style={{margin: ".2rem .5rem"}}>{value}</div>
        </div>
    )
}