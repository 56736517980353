export default function TabButtonPanel() {
  const openTab = (e, tabName) => {
    let i, tabcontent, tablinks;

    tabcontent = document.getElementsByClassName("tabContent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(e.currentTarget.name).style.display = "block";
    e.currentTarget.className += " active";
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", borderBottom: "1px solid #1a2649", margin: "0", padding: "0" }}>
      <div className="tabs">
        <button
          className="tab-btn tablinks active"
          name="general"
          onClick={openTab}
        >
          General
        </button>
        <button className="tab-btn tablinks" name="tables" onClick={openTab}>
          Tables
        </button>
        <button className="tab-btn tablinks" name="schedules" onClick={openTab}>
          Schedules
        </button>
      </div>
    </div>
    
  );
}
