import "./App.css";
import ResultsLayout from "./components/ResultsLayout";
import FileSelector from "./components/FileSelector";
import { useState } from "react";

function App() {
  const [xer, setXer] = useState(undefined);
  const [message, setMessage] = useState("");

  return (
    <div className="App">
      <div
        style={{
          visibility: "hidden",
          opacity: "0",
          textAlign: "center",
          padding: "40vh 0",
          fontSize: "2.5em",
          fontWeight: "bold",
        }}
        className="dropzone"
      >
        DROP XER FILE
      </div>
      <header>
        <button onClick={() => document.location.reload()} className="plain">
          <h1>XER INSPECTOR</h1>
        </button>
        <FileSelector xer={xer} setXer={setXer} setMessage={setMessage} />
        <div
          className="warning"
          style={{ marginTop: "2rem", fontSize: "1.1em" }}
        >
          {message}
        </div>
      </header>
      {!!xer && (
        <div>
          <ResultsLayout xer={xer} setXer={setXer} />
        </div>
      )}
    </div>
  );
}

export default App;
