export default function CheckBox({ label, checked, disabled, func, name }) {
  return (
    <div
      style={{
        alignContent: "center",
        padding: ".25rem",
        display: "flex",
        textAlign: "left",
      }}
    >
      <input
        defaultChecked={checked ?? true}
        disabled={disabled ?? false}
        type="checkbox"
        id={name}
        name={name}
        value={name}
        onChange={() => func(!checked)}
        style={{ scale: "140%" }}
      />
      <label htmlFor={name} style={{ marginLeft: ".5rem" }}>
        {label}
      </label>
    </div>
  );
}
