export default class Project {
  #budgetedCost = undefined;
  #actualCost = undefined;
  #thisPeriodCost = undefined;
  #remainingCost = undefined;
  #percentComplete = undefined;
  constructor(data) {
    Object.assign(this, data);
    this.tasks = {};
    this.resources = {};
    this.wbs_root = undefined;
  }

  addTask(task) {
    this.tasks[task.task_code] = task;
  }

  addResource(res) {
    this.resources[res.taskrsrc_id] = res;
  }

  get name() {
    return this.wbs_root?.wbs_name;
  }

  get budgetedCost() {
    if (this.#budgetedCost) return this.#budgetedCost;
    this.#budgetedCost = Object.values(this.resources).reduce((total, res) => {
      return total + res.target_cost;
    }, 0);
    return this.#budgetedCost;
  }

  get actualCost() {
    if (this.#actualCost) return this.#actualCost;
    this.#actualCost = Object.values(this.resources).reduce((total, res) => {
      return total + res.act_reg_cost + res.act_ot_cost;
    }, 0);
    return this.#actualCost;
  }

  get thisPeriodCost() {
    if (this.#thisPeriodCost) return this.#thisPeriodCost;
    this.#thisPeriodCost = Object.values(this.resources).reduce(
      (total, res) => {
        return total + res.act_this_per_cost;
      },
      0
    );
    return this.#thisPeriodCost;
  }

  get remainingCost() {
    if (this.#remainingCost) return this.#remainingCost;
    this.#remainingCost = Object.values(this.resources).reduce((total, res) => {
      return total + res.remain_cost;
    }, 0);
    return this.#remainingCost;
  }

  get atCompletionCost() {
    return this.actualCost + this.remainingCost;
  }

  get percentComplete() {
    if (this.#percentComplete !== undefined) return this.#percentComplete;
    let startFinish = 0;
    let origDur = 0;
    let remDur = 0;
    let taskCount = Object.keys(this.tasks).length;
    for (const task of Object.values(this.tasks)) {
      if (task.task_type !== "TT_LOE") {
        origDur += task.target_drtn_hr_cnt;
        remDur += task.remain_drtn_hr_cnt;
      }
      if (task.status_code === "TK_Active") startFinish += 1;
      if (task.status_code === "TK_Complete") startFinish += 2;
    }
    const startFinishPercent =
      taskCount > 0 ? startFinish / (taskCount * 2) : 0;
    const durationPercent = origDur > 0 ? 1 - remDur / origDur : 0;
    this.#percentComplete = ((startFinishPercent + durationPercent) / 2) * 100;
    return this.#percentComplete;
  }
}
