export class InvalidFileType extends Error {
    constructor(fileName=undefined) {
        const fileType = fileName ?? fileName.match(/\.[0-9a-z]+$/i)[0]
        super(`Wrong File Type${!fileType ? "" : " " + fileType}: Excpected .xer`)
        this.fileName = fileName
        this.fileType = fileType
    }
}
export class InvalidXer extends Error {
    constructor(message) {
        super(`Invalid XER File: ${message}`)
    }
}