import { useState } from "react";
import DataRow from "./DataRow";
import TabButtonPanel from "./TabButtonPanel";
import CheckBox from "./CheckBox";
import downloadFile from "../utils/downloadFile";
import H3Label from "./H3Label";
import { Container, LinearProgress, Stack } from "@mui/material";
import { format } from "date-fns";
import { findMissingTables, findOrphans } from "../utils/missingData";
import TableDialog from "./TableDialog";

const warningStyle = {
  color: "#ffab00",
  textAlign: "left",
  padding: ".25rem .5rem",
};

const tableRowStyle = {
  display: "flex",
  justifyContent: "space-between",
  margin: "0 .5rem",
  padding: ".25rem .5rem",
  fontSize: "0.9em",
};

const dateFormat = "MMM d, y";

export default function ResultsLayout({ xer }) {
  const missingTables = findMissingTables(xer.tables);
  const orphans = findOrphans(xer.tables);
  const schedules = Object.values(xer.tables.PROJECT.data).filter(
    (project) => project.export_flag
  );

  const riskTableRegEx =
    /%T\t(RISKTYPE|PRORISK)[\n\r](.*[\n\r])*?(?=^(%T|%E))/gm;
  const pobsTableRegEx = /%T\tPOBS[\n\r](.*[\n\r])*?(?=^(%T|%E))/gm;
  const udfTableRegEx =
    /%T\t(UDFTYPE|UDFVALUE)[\n\r](.*[\n\r])*?(?=^(%T|%E))/gm;

  const foundRiskTable = riskTableRegEx.test(xer.data);
  const foundPobsTable = pobsTableRegEx.test(xer.data);
  const foundUdfs = udfTableRegEx.test(xer.data);

  const [cleanPobs, setCleanPobs] = useState(true);
  const [cleanRisks, setCleanRisks] = useState(true);
  const [cleanUdfs, setCleanUdfs] = useState(false);

  const cleanFile = () => {
    let cleanFileString = xer.data;
    if (cleanPobs && foundPobsTable) {
      cleanFileString = cleanFileString.replace(pobsTableRegEx, "");
    }
    if (cleanRisks && foundRiskTable) {
      cleanFileString = cleanFileString.replace(riskTableRegEx, "");
    }
    if (cleanUdfs && foundUdfs) {
      cleanFileString = cleanFileString.replace(udfTableRegEx, "");
    }
    const cleanFile = new File([cleanFileString], `${xer.info.fileName}`, {
      type: "cp1252",
    });

    downloadFile(cleanFile);
  };

  return (
    <Container maxWidth="md">
      <div id="results">
        <div style={{ textAlign: "center" }}>
          <TabButtonPanel />
        </div>
        <div className="tabContent" id="general" style={{ display: "block" }}>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <div>
              <H3Label text="File Info" />
              <table aria-label="file-info">
                <tbody>
                  <tr>
                    <td style={{ width: "140px" }}>P6 Version:</td>
                    <td>{xer.info.version}</td>
                  </tr>
                  <tr>
                    <td>Created On:</td>
                    <td>{format(xer.info.created_date, dateFormat)}</td>
                  </tr>
                  <tr>
                    <td>Created By:</td>
                    <td>{xer.info.created_by}</td>
                  </tr>
                  <tr>
                    <td>Schedule Count:</td>
                    <td>{schedules.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ borderTop: "1px solid #1a2649" }}>
              <H3Label text="File Errors" />
              {missingTables.size === 0 && orphans.size === 0 ? (
                <DataRow label={"No File Errors Found"} value={""} />
              ) : (
                <div>
                  {[...missingTables].map((table, index) => (
                    <div key={index} style={warningStyle}>
                      {table}
                    </div>
                  ))}
                  {[...orphans].map((orphan, index) => (
                    <div key={index} style={warningStyle}>
                      {orphan}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div style={{ borderTop: "1px solid #1a2649" }}>
              <H3Label text="File Cleaning" />
              {foundPobsTable || foundRiskTable || foundUdfs ? (
                <div>
                  <div style={{ margin: ".5rem .5rem", textAlign: "left" }}>
                    Clear Problamatic / Uneccessary File Data
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div style={{ textAlign: "left", width: "fit-content" }}>
                      {foundPobsTable && (
                        <CheckBox
                          label={`Remove POBS Data [${
                            Object.keys(xer.tables.POBS.data).length
                          } Entries]`}
                          checked={cleanPobs}
                          disabled={!foundPobsTable}
                          func={setCleanPobs}
                          name="pobs"
                        />
                      )}
                      {foundRiskTable && (
                        <CheckBox
                          label={`Remove Risk Data [${
                            Object.keys(xer.tables.RISKTYPE.data).length
                          } Entries]`}
                          checked={cleanRisks}
                          disabled={!foundRiskTable}
                          func={setCleanRisks}
                          name="risk"
                        />
                      )}
                      {foundUdfs && (
                        <CheckBox
                          label={`Remove UDF Data [${
                            Object.keys(xer.tables.UDFVALUE.data).length
                          } Entries]`}
                          checked={cleanUdfs}
                          disabled={!foundUdfs}
                          func={setCleanUdfs}
                          name="udf"
                        />
                      )}
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <button
                      className="button"
                      onClick={cleanFile}
                      style={{ marginTop: "1rem" }}
                      disabled={
                        (!cleanPobs || !foundPobsTable) &&
                        (!cleanRisks || !foundRiskTable) &&
                        (!cleanUdfs || !foundUdfs)
                      }
                    >
                      Download Clean File
                    </button>
                  </div>
                </div>
              ) : (
                <div style={{ margin: ".5rem .5rem", textAlign: "left" }}>
                  No Problamatic / Uneccessary File Data Found
                </div>
              )}
            </div>
          </Stack>
        </div>
        <div
          className="tabContent"
          id="tables"
          style={{ width: "fit-content" }}
        >
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Table Name</th>
                <th style={{ textAlign: "left" }}>Description</th>
                <th style={{ textAlign: "right" }}>Entries</th>
                <th style={{ textAlign: "center" }}>View</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(xer.tables).map(
                ([name, table]) =>
                  !!table.data && (
                    <tr
                      key={name}
                      className={
                        name === "RISKTYPE" || name === "POBS"
                          ? "hover warning"
                          : "hover"
                      }
                    >
                      <td>{name}</td>
                      <td>{table.description}</td>
                      <td style={{ textAlign: "right" }}>
                        {Object.keys(table.data).length.toLocaleString()}
                      </td>
                      <td>
                        {!!table.display && <TableDialog table={table} />}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
        <div className="tabContent" id="schedules">
          <Stack spacing={2}>
            {schedules.map((project) => (
              <div key={project.proj_id} className={"card hover"}>
                <div style={tableRowStyle}>
                  <div>
                    <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>
                      {project.proj_short_name}
                    </div>
                    <div style={{ marginBottom: "0.5rem" }}>{project.name}</div>
                    <table style={{ width: "340px" }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "110px" }}>Data Date:</td>
                          <td>
                            {!project.last_recalc_date
                              ? "None"
                              : format(project.last_recalc_date, dateFormat)}
                          </td>
                        </tr>
                        <tr>
                          <td>End Date:</td>
                          <td>
                            {!project.scd_end_date
                              ? "None"
                              : format(project.scd_end_date, dateFormat)}
                          </td>
                        </tr>
                        <tr>
                          <td>Activities:</td>
                          <td>
                            {Object.keys(project.tasks).length.toLocaleString()}
                          </td>
                        </tr>
                        {project.budgetedCost !== 0 && (
                          <tr>
                            <td>Cost Loading:</td>
                            <td>{`$${project.budgetedCost.toLocaleString()}`}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: ".2rem .75rem",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <LinearProgress
                      variant="determinate"
                      value={project.percentComplete}
                      sx={{ height: 12 }}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      minWidth: "40px",
                      color: "#bdbdbd",
                      paddingBottom: "0.1rem",
                      fontSize: "0.9em",
                    }}
                  >{`${Math.round(project.percentComplete)}%`}</div>
                </div>
              </div>
            ))}
          </Stack>
        </div>
      </div>
    </Container>
  );
}
