import { add } from "date-fns";
import { InvalidFileType, InvalidXer } from "../errors/errors";
import { Node } from "../schema/wbs";
import { tableMap } from "../schema/TableData";
import Project from "../schema/project";
import Task from "../schema/task";
import TaskRsrc from "../schema/taskrsrc";

const ermhdrMap = {
  // map ERHMHDR data to array index
  version: 0,
  exportDate: 1,
  exportBy: 4,
};

const parseFileInfo = (info, name) => {
  return {
    fileName: name,
    version: info[ermhdrMap.version],
    created_date: add(new Date(info[ermhdrMap.exportDate]), { days: 1 }),
    created_by: info[ermhdrMap.exportBy],
  };
};

const zip = (labels, values) => {
  const zippedArray = labels.reduce((entry, label, index) => {
    const value = setDataType(label, values[index]);
    entry[label] = value;
    return entry;
  }, {});

  return zippedArray;
};

export const parseTables = (file, fileName) => {
  if (!fileName.endsWith(".xer")) {
    throw new InvalidFileType(fileName);
  }

  if (!file.startsWith("ERMHDR\t")) {
    throw new InvalidXer(`<${fileName}> is Missing File Header (ERMHDR)`);
  }

  const xer = { data: file, tables: {} };

  const setObject = (name, data) => {
    switch (name) {
      case "PROJECT":
        return new Project(data);
      case "PROJWBS":
        const node = new Node(data);
        if (node.proj_node_flag) {
          const proj = xer.tables.PROJECT.data[node.proj_id];
          proj.wbs_root = node;
        }
        return node;
      case "TASK":
        const task = new Task(data);
        xer.tables.PROJECT.data[task.proj_id].addTask(task);
        xer.tables.PROJWBS.data[task.wbs_id].addTask(task);
        return task;
      case "TASKRSRC":
        const res = new TaskRsrc(data);
        xer.tables.PROJECT.data[res.proj_id].addResource(res);
        xer.tables.TASK.data[res.task_id].addResource(res);
        return res;
      default:
        return data;
    }
  };

  const tables = file.split(/%T\t/gm);
  for (let table of tables) {
    if (table.startsWith("ERMHDR")) {
      xer.info = parseFileInfo(table.split("\t").slice(1), fileName);
      continue;
    }
    const rows = table.split("\r\n");
    const name = rows.shift();
    const key = tableMap[name]?.uid;
    const labels = rows.shift().split("\t").slice(1);
    const entries = {};
    for (const [index, row] of rows.entries()) {
      if (!row || !row.startsWith("%R")) continue;
      const values = row.split("\t").slice(1);
      const entry = setObject(name, zip(labels, values));
      if (key) {
        entries[entry[key]] = entry;
      } else {
        entries[index] = entry;
      }
    }
    xer.tables[name] = {
      name: name,
      key: key,
      description: tableMap[name]?.description ?? "Unknown",
      data: entries,
      display: tableMap[name]?.display ?? undefined,
    };
  }

  for (let node of Object.values(xer.tables.PROJWBS.data)) {
    if (node.proj_node_flag) continue
    const parent = xer.tables.PROJWBS.data[node.parent_wbs_id];
    node.parent = parent
    parent.addChild(node)
  }

  return xer;
};

const setDataType = (col, val) => {
  if (!col) return;
  if (/.+_date2*$/.test(col)) {
    return val === "" ? NaN : new Date(val);
  }
  if (/.+_(num|number)$/.test(col)) {
    return val === "" ? NaN : parseInt(val);
  }
  if (/.+_(cost|qty|cnt)$/.test(col)) {
    return val === "" ? NaN : parseFloat(val);
  }
  if (col.endsWith("_flag")) {
    return val === "Y" ? true : val === "N" ? false : undefined;
  }
  return val;
};
