import { useState } from "react";
import { parseTables } from "../utils/parser";
import { CircularProgress } from "@mui/material";

export default function FileSelector({ xer, setXer, setMessage }) {
  const [loading, setLoading] = useState(false);

  var lastTarget = null;

  window.addEventListener("dragover", function (e) {
    e.preventDefault();
  });

  window.addEventListener("dragenter", function (e) {
    lastTarget = e.target;
    var dropzone = document.querySelector(".dropzone");
    dropzone.style.visibility = "";
    dropzone.style.opacity = 1;
  });

  window.addEventListener("dragleave", function (e) {
    if (e.target === lastTarget || e.target === document) {
      document.querySelector(".dropzone").style.visibility = "hidden";
      document.querySelector(".dropzone").style.opacity = 0;
    }
  });

  window.addEventListener("drop", function (e) {
    e.preventDefault();
    var dropzone = document.querySelector(".dropzone");
    dropzone.style.visibility = "hidden";
    dropzone.style.opacity = 0;
    const file = e.dataTransfer.files[0];
    processFile(file);
  });

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    processFile(file);
  };

  const processFile = (file) => {
    if (file) {
      if (file.name.endsWith(".xer")) {
        let reader = new FileReader();
        reader.onloadstart = () => {
          setLoading(true);
          setXer(undefined);
          setMessage("");
        };
        reader.onload = (r) => {
          try {
            const tables2 = parseTables(r.target.result, file.name);
            setXer(tables2)
          } catch (error) {
            setMessage(error.message);
          }
        };
        reader.onerror = (err) => {
          setMessage(`Error: Unable to read file <${file.name}>\n${err}`);
        };
        reader.onloadend = () => {
          setLoading(false);
        };
        reader.readAsText(file, "cp1252");
      } else {
        setXer(undefined);
        setMessage("Wrong File Type");
      }
    }
  };

  return (
    <div>
      <div id="upload">
        <label htmlFor="file-selector" className="file-select">
          {!!xer
            ? xer.info.fileName
            : loading
            ? "Loading File..."
            : "Click Here or Drop File Anywhere"}
        </label>
        <input
          type="file"
          name="file"
          id="file-selector"
          accept=".xer"
          onChange={handleChangeFile}
          style={{ display: "block" }}
        />
      </div>
      {loading && <CircularProgress size={48} sx={{ mt: 3 }} />}
    </div>
  );
}
