import { tableMap } from "../schema/TableData";

export function findMissingTables(tables) {
  let missing = new Set();

  for (let [name, table] of Object.entries(tableMap)) {
    if (table.required && !(name in tables)) {
      missing.add(`Missing ${name} table`);
    }
  }
  for (let table of Object.keys(tables)) {
    for (let table2 of tableMap[table]?.depends ?? []) {
      if (!(table2 in tables)) {
        missing.add(`Missing ${table2} table`);
      }
    }
  }
  return missing;
}

export function findOrphans(tables) {
    const keyMap = {};
    for (let [name, table] of Object.entries(tableMap)) {
      if (table.uid) {
        keyMap[table.uid] = name;
      }
    }
  
    let orphan = new Set();
    for (let [name, table] of Object.entries(tables)) {
      for (let row of Object.values(table.data)) {
        for (let [key, value] of Object.entries(row)) {
          if (key in keyMap && name !== keyMap[key] && value !== "") {
            if (!(keyMap[key] in tables) || !(value in tables[keyMap[key]].data)) {
              orphan.add(
                `Orphan ${keyMap[key]} ${value} (${key}) at ${name} entries`
              );
            }
          }
        }
      }
    }
    return orphan;
  }
  