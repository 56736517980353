import { useEffect, useState } from "react";

const buttonSytle = {
  backgroundColor: "#000102",
  color: "rgb(221, 221, 221)",
  cursor: "pointer",
  border: "0",
};

export default function TableDialog({ table }) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(0);
  const [ascending, setAscending] = useState(true);

  const handleOpen = () => {
    getData();
    document.getElementById(`${table.name}-dialog`).showModal();
  };

  const handleClose = () => {
    document.getElementById(`${table.name}-dialog`).close();
  };

  useEffect(() => {
    getData();
  }, [sort, ascending]);

  const compareProperty = (a, b) => {
    const property = Object.keys(table.display)[sort];
    if (a[property] < b[property]) {
      return ascending ? -1 : 1;
    } else if (a[property] > b[property]) {
      return ascending ? 1 : -1;
    } else {
      return 0;
    }
  };

  const isString = (value) =>
    typeof value === "string" || value instanceof String;

  const getData = () => {
    setData(
      Object.values(table.data)
        .filter((entry) => {
          for (const label of Object.keys(table.display)) {
            if (isString(entry[label])) {
              if (entry[label].toLowerCase().includes(search.toLowerCase())) {
                return true;
              }
            }
          }
          return false;
        })
        .sort(compareProperty)
    );
  };

  const searchChange = (e) => {
    setSearch(e.target.value);

    setData(
      Object.values(table.data)
        .filter((entry) => {
          for (const label of Object.keys(table.display)) {
            if (isString(entry[label])) {
              if (
                entry[label]
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                return true;
              }
            }
          }
          return false;
        })
        .sort(compareProperty)
    );
  };

  return (
    <>
      <button style={buttonSytle} onClick={handleOpen}>
        View
      </button>
      <dialog id={`${table.name}-dialog`} data-modal>
        <h3 style={{ margin: "0.25rem 2rem 0.25rem 0" }}>{table.name}</h3>
        <h4 style={{ margin: "0.25rem 0 0.5rem" }}>{table.description}</h4>
        <div style={{ maxHeight: "400px", overflow: "auto" }}>
          <table>
            <thead>
              <tr>
                {Object.values(table.display).map((title, index) => (
                  <th key={index} style={{ textAlign: "left" }}>
                    <button
                      className={
                        index !== sort ? "" : ascending ? "down" : "up"
                      }
                      onClick={() => {
                        if (index === sort) {
                          setAscending(!ascending);
                        } else {
                          setAscending(true);
                          setSort(index);
                        }
                      }}
                    >
                      {title}
                    </button>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((entry, index) => (
                <tr key={index} className="hover">
                  {Object.keys(table.display).map((key) => (
                    <td key={key}>{entry[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <input
            style={{ marginRight: "1rem" }}
            type="text"
            placeholder="Search.."
            onChange={searchChange}
          />
          <button className="button" onClick={handleClose}>
            Close
          </button>
        </div>
      </dialog>
    </>
  );
}
