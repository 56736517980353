export default function H3Label({ text }) {
  return (
    <h3
      style={{
        margin: "0.25rem 0",
        padding: "0.25rem 0",
        textAlign: "left",
      }}
    >
      {text}
    </h3>
  );
}
