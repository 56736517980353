import Task from "./task";

export class Node {
  constructor(data) {
    Object.assign(this, data);
    this.parent = undefined;
    this.children = [];
    this.tasks = [];
  }

  addChild(node) {
    if (!(node instanceof Node)) {
      throw new Error("Argument must be type 'Node'!")
    }
    this.children.push(node);
  }

  addTask(task) {
    console.log(task.constructor.name)
    if (!(task instanceof Task)) {
      throw new Error("Argument must be type 'Task'!")
    }
    this.tasks.push(task);
  }

  get allTasks() {
    let tasks = [...this.tasks];
    for (let child of this.children) {
      tasks = tasks.concat(child.allTasks);
    }
    return tasks;
  }

  get wbs_code() {
    return this.descent.map((node) => node.wbs_short_name).join(".");
  }

  get descent() {
    if (this.isProjectNode || !this.parent) return [this];
    return this.parent.descent.concat([this]);
  }

  print(indent = "") {
    console.log(
      indent + this.wbs_name,
      `[${this.allTasks.length.toLocaleString()} Activities]`
    );
    for (let child of this.children) {
      child.print(indent + "  ");
    }
  }
}
