export const tableMap = {
  ACCOUNT: {
    uid: "acct_id",
    description: "Cost Accounts",
    required: false,
    depends: [],
    display: {acct_short_name: "Account ID", acct_name: "Account Name"}
  },
  ACTVCODE: {
    uid: "actv_code_id",
    description: "Activity Code Values",
    required: false,
    depends: ["ACTVTYPE"],
  },
  ACTVTYPE: {
    uid: "actv_code_type_id",
    description: "Activity Codes",
    required: false,
    depends: [],
    display: {actv_code_type: "Activity Code"}
  },
  APPLYACTOPTIONS: {
    uid: undefined,
    description: "Apply Actual Options",
    required: false,
    depends: [],
  },
  ASGNMNTACAT: {
    uid: undefined,
    description: "Assignment Code Assignments",
    required: false,
    depends: ["ASGNMNTCATTYPE", "ASGNMNTCATVAL", "TASKRSRC"],
  },
  ASGNMNTCATTYPE: {
    uid: "asgnmnt_catg_type_id",
    description: "Assignment Codes",
    required: false,
    depends: [],
  },
  ASGNMNTCATVAL: {
    uid: "asgnmnt_catg_id",
    description: "Assignment Code Values",
    required: false,
    depends: ["ASGNMNTCATTYPE"],
  },
  BUDGCHNG: {
    uid: "budg_chng_id",
    description: "Budget Changes",
    required: false,
    depends: [],
  },
  CALENDAR: {
    uid: "clndr_id",
    description: "Calendars",
    required: true,
    depends: [],
    display: {clndr_name: "Name", clndr_type: "Type"}
  },
  COSTTYPE: {
    uid: "cost_type_id",
    description: "Expense Categories",
    required: false,
    depends: [],
  },
  CURRTYPE: {
    uid: "curr_id",
    description: "Currency Types",
    required: false,
    depends: [],
  },
  DOCCATG: {
    uid: "doc_catg_id",
    description: "Document Categories",
    required: false,
    depends: [],
  },
  DOCSTAT: {
    uid: "doc_status_id",
    description: "Document Statuses",
    required: false,
    depends: [],
  },
  DOCUMENT: {
    uid: "doc_id",
    description: "Work Products and Documents",
    required: false,
    depends: [],
  },
  FINDATES: {
    uid: "fin_dates_id",
    description: "Financial Periods",
    required: false,
    depends: [],
    display: {fin_dates_name: "Period Name"}
  },
  FUNDSRC: {
    uid: "fund_id",
    description: "Funding Sources",
    required: false,
    depends: [],
  },
  ISSUHIST: {
    uid: undefined,
    description: "Notification History",
    required: false,
    depends: [],
  },
  MEMOTYPE: {
    uid: "memo_type_id",
    description: "Notebook Topics",
    required: false,
    depends: [],
    display: {memo_type: "Notebook Topic"}
  },
  NONWORK: {
    uid: "nonwork_type_id",
    description: "Non-work Types",
    required: false,
    depends: [],
  },
  OBS: {
    uid: "obs_id",
    description: "Organization Breakdown Structure",
    required: false,
    depends: [],
    display: {obs_name: "OBS Name"}
  },
  PCATTYPE: {
    uid: "proj_catg_type_id",
    description: "Project Codes",
    required: false,
    depends: [],
  },
  PCATVAL: {
    uid: "proj_catg_id",
    description: "Project Code Values",
    required: false,
    depends: ["PCATTYPE"],
  },
  PHASE: {
    uid: "phase_id",
    description: "Phase Project Code",
    required: false,
    depends: [],
  },
  POBS: {
    uid: "pobs_id",
    description: "Extra Data",
    required: false,
    depends: [],
  },
  PROJCOST: {
    uid: "cost_item_id",
    description: "Project Expenses",
    required: false,
    depends: [],
  },
  PROJECT: {
    uid: "proj_id",
    description: "Projects",
    required: true,
    depends: [],
  },
  PROJEST: {
    uid: "proj_est_id",
    description: "Estimate History",
    required: false,
    depends: [],
  },
  PROJFUND: {
    uid: "proj_fund_id",
    description: "Project Funding Assignments",
    required: false,
    depends: ["FUNDSRC"],
  },
  PROJISSU: {
    uid: "issue_id",
    description: "Issues",
    required: false,
    depends: [],
  },
  PROJPCAT: {
    uid: undefined,
    description: "Project Code Assignments",
    required: false,
    depends: [],
  },
  PROJTHRS: {
    uid: "thresh_id",
    description: "Thresholds",
    required: false,
    depends: [],
  },
  PROJWBS: {
    uid: "wbs_id",
    description: "Work Breakdown Structure",
    required: true,
    depends: [],
  },
  PRORISK: {
    uid: "risk_id",
    description: "Risks",
    required: false,
    depends: ["RISKTYPE"],
  },
  RCATTYPE: {
    uid: "rsrc_catg_type_id",
    description: "Resource Codes",
    required: false,
    depends: [],
  },
  RCATVAL: {
    uid: "rsrc_catg_id",
    description: "Resource Code Values",
    required: false,
    depends: ["RCATTYPE"],
  },
  RISKTYPE: {
    uid: "risk_type_id",
    description: "Risk Types",
    required: false,
    depends: [],
  },
  ROLECATTYPE: {
    uid: "role_catg_type_id",
    description: "Role Codes",
    required: false,
    depends: [],
  },
  ROLECATVAL: {
    uid: "role_catg_id",
    description: "Role Code Values",
    required: false,
    depends: ["ROLECATTYPE"],
  },
  ROLELIMIT: {
    uid: "rolelimit_id",
    description: "Role Limits",
    required: false,
    depends: ["ROLES"],
  },
  ROLERATE: {
    uid: "role_rate_id",
    description: "Role Prices",
    required: false,
    depends: ["ROLES"],
  },
  ROLERCAT: {
    uid: undefined,
    description: "Role Code Assignments",
    required: false,
    depends: ["ROLES", "ROLECATTYPE", "ROLECATVAL"],
  },
  ROLES: {
    uid: "role_id",
    description: "Roles",
    required: false,
    depends: [],
  },
  RSRC: {
    uid: "rsrc_id",
    description: "Resources",
    required: false,
    depends: [],
    display: {rsrc_short_name: "Resource ID", rsrc_name: "Resource Name"}
  },
  RSRCCURV: {
    uid: "curv_id",
    description: "Resource Curves",
    required: false,
    depends: [],
  },
  RSRCCURVDATA: {
    uid: "curv_id",
    description: "Resource Curve Data",
    required: false,
    depends: [],
  },
  RSRCLEVELLIST: {
    uid: "rsrc_level_list_id",
    description: "Resource Level List",
    required: false,
    depends: ["RSRC"],
  },
  RSRCRATE: {
    uid: "rsrc_rate_id",
    description: "Resource Prices",
    required: false,
    depends: ["RSRC"],
  },
  RSRCRCAT: {
    uid: undefined,
    description: "Resource Code Assignments",
    required: false,
    depends: ["RCATVAL", "RCATTYPE", "RSRC"],
  },
  RSRCROLE: {
    uid: undefined,
    description: "Resource Role Assignments",
    required: false,
    depends: ["ROLES", "RSRC"],
  },
  SCHEDOPTIONS: {
    uid: "schedoptions_id",
    description: "Schedule Options",
    required: false,
    depends: [],
  },
  SHIFT: {
    uid: "shift_id",
    description: "Shift Names",
    required: false,
    depends: [],
  },
  SHIFTPER: {
    uid: "shift_period_id",
    description: "Shifts",
    required: false,
    depends: ["SHIFT"],
  },
  TASK: {
    uid: "task_id",
    description: "Activities",
    required: true,
    depends: [],
    display: {task_code: "ID", task_name: "Activity Name"}
  },
  TASKACTV: {
    uid: undefined,
    description: "Activity Code Assignments",
    required: false,
    depends: ["ACTVCODE"],
  },
  TASKDOC: {
    uid: "taskdoc_id",
    description: "Document Assignments",
    required: false,
    depends: ["DOCUMENT"],
  },
  TASKFDBK: {
    uid: undefined,
    description: "Activity Feedback",
    required: false,
    depends: [],
  },
  TASKFIN: {
    uid: undefined,
    description: "Activity Past Period Actuals",
    required: false,
    depends: ["FINDATES"],
  },
  TASKMEMO: {
    uid: "memo_id",
    description: "Activity Notebook",
    required: false,
    depends: ["MEMOTYPE"],
  },
  TASKNOTE: {
    uid: undefined,
    description: "Activity Notes to Resources",
    required: false,
    depends: [],
  },
  TASKPRED: {
    uid: "task_pred_id",
    description: "Activity Relationships",
    required: true,
    depends: [],
  },
  TASKPROC: {
    uid: "proc_id",
    description: "Activity Steps",
    required: false,
    depends: [],
  },
  TASKRSRC: {
    uid: "taskrsrc_id",
    description: "Activity Resource Assignments",
    required: false,
    depends: ["RSRC"],
  },
  TASKUSER: {
    uid: undefined,
    description: "Activity Owners",
    required: false,
    depends: [],
  },
  THRSPARM: {
    uid: "thresh_parm_id",
    description: "Threshold Parameters",
    required: false,
    depends: [],
  },
  TRSRCFIN: {
    uid: undefined,
    description: "Activity Resource Assignment Past Period Actuals",
    required: false,
    depends: ["FINDATES", "TASKRSRC"],
  },
  UDFTYPE: {
    uid: "udf_type_id",
    description: "User Defined Fields",
    required: false,
    depends: [],
    display: {udf_type_label: "UDF"}
  },
  UDFVALUE: {
    uid: undefined,
    description: "User Defined Field Values",
    required: false,
    depends: ["UDFTYPE"],
  },
  UMEASURE: {
    uid: "unit_id",
    description: "Units of Measure",
    required: false,
    depends: [],
  },
  WBSBUDG: {
    uid: "wbs_budg_id",
    description: "Spending and Benefit Plans",
    required: false,
    depends: [],
  },
  WBSMEMO: {
    uid: "wbs_memo_id",
    description: "EPS, Project and WBS Notebook",
    required: false,
    depends: ["MEMOTYPE"],
  },
  WBSSTEP: {
    uid: "wbs_step_id",
    description: "WBS Milestones",
    required: false,
    depends: [],
  },
};
