class TaskStatus {
  static TK_NotStart = "Not Started";
  static TK_Active = "In Progress";
  static TK_Complete = "Complete";

  constructor(status) {
    this.code = status;
    this.value = TaskStatus[status];
  }

  get isNotStarted() {
    return this.value === TaskStatus.TK_NotStart;
  }

  get isInProgress() {
    return this.value === TaskStatus.TK_Active;
  }

  get isComplete() {
    return this.value === TaskStatus.TK_Complete;
  }
}

class PercentType {
  static CP_Phys = "Physical"
  static CP_Drtn = "Duration"
  static CP_Units = "Unit"
}

class TaskType {
  static TT_Mile = "Start Milestone";
  static TT_FinMile = "Finish Milestone";
  static TT_LOE = "Level of Effort";
  static TT_Task = "Task Dependent";
  static TT_Rsrc = "Resource Dependent";
  static TT_WBS = "WBS Summary";

  constructor(type) {
    this.code = type;
    this.value = TaskType[type];
  }

  get isLOE() {
    return this.value === TaskType.TT_LOE;
  }

  get isMilestone() {
    return (
      this.value === TaskType.TT_Mile || this.value === TaskType.TT_FinMile
    );
  }

  get isTask() {
    return this.value === TaskType.TT_Task;
  }

  get isResource() {
    return this.value === TaskType.TT_Rsrc;
  }

  get isWbsSummary() {
    return this.value === TaskType.TT_WBS;
  }
}

class Constraint {
  static CS_ALAP = "As Late as Possible";
  static CS_MEO = "Finish On";
  static CS_MEOA = "Finish on or After";
  static CS_MEOB = "Finish on or Before";
  static CS_MANDFIN = "Mandatory Finish";
  static CS_MANDSTART = "Mandatory Start";
  static CS_MSO = "Start On";
  static CS_MSOA = "Start On or After";
  static CS_MSOB = "Start On or Before";

  constructor(type, date) {
    this.code = type;
    this.date = date;
    this.value = Constraint[type] ?? undefined;
  }

  get isHard() {
    return (
      this.code === Constraint.CS_MANDFIN ||
      this.code === Constraint.CS_MANDSTART
    );
  }
}

export default class Task {
  #budgetedCost = undefined;
  #actualCost = undefined;
  #thisPeriodCost = undefined;
  #remainingCost = undefined;
  constructor(data) {
    Object.assign(this, data);
    this.status = new TaskStatus(this.status_code);
    this.type = new TaskType(this.task_type);
    this.primaryConstraint = new Constraint(this.cstr_type, this.cstr_date);
    this.secondConstraint = new Constraint(this.cstr_type2, this.cstr_date2);
    this.percentType = PercentType[this.complete_pct_type];
    this.resources = {};
    this.memos = {};
  }

  addResource(res) {
    this.resources[res.taskrsrc_id] = res;
  }

  get budgetedCost() {
    if (this.#budgetedCost) return this.#budgetedCost;
    this.#budgetedCost = Object.values(this.resources).reduce((total, res) => {
      return total + res.target_cost;
    }, 0);
    return this.#budgetedCost;
  }

  get actualCost() {
    if (this.#actualCost) return this.#actualCost;
    this.#actualCost = Object.values(this.resources).reduce((total, res) => {
      return total + res.act_reg_cost + res.act_ot_cost;
    }, 0);
    return this.#actualCost;
  }

  get thisPeriodCost() {
    if (this.#thisPeriodCost) return this.#thisPeriodCost;
    this.#thisPeriodCost = Object.values(this.resources).reduce(
      (total, res) => {
        return total + res.act_this_per_cost;
      },
      0
    );
    return this.#thisPeriodCost;
  }

  get remainingCost() {
    if (this.#remainingCost) return this.#remainingCost;
    this.#remainingCost = Object.values(this.resources).reduce((total, res) => {
      return total + res.remain_cost;
    }, 0);
    return this.#remainingCost;
  }

  get atCompletionCost() {
    return this.actualCost + this.remainingCost;
  }

  equals(other) {
    if (other instanceof Task) {
      return this.task_code === other.task_code;
    }
    return false;
  }

  get hash() {
    return this.task_code.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }
}

// const task1 = new Task({
//   status_code: "TK_NotStart",
//   task_type: "TT_Task",
//   cstr_type: "",
//   cstr_date: NaN,
//   cstr_type2: "",
//   cstr_date2: NaN,
//   task_code: "A1000",
// })
// const task2 = new Task({
//   status_code: "TK_NotStart",
//   task_type: "TT_Task",
//   cstr_type: "",
//   cstr_date: NaN,
//   cstr_type2: "",
//   cstr_date2: NaN,
//   task_code: "A1010",
// })
// const task3 = new Task({
//   status_code: "TK_NotStart",
//   task_type: "TT_Task",
//   cstr_type: "",
//   cstr_date: NaN,
//   cstr_type2: "",
//   cstr_date2: NaN,
//   task_code: "A1010",
// })

// console.log(task1.hash)
// console.log(task2.hash)
// console.log(task3.hash)
